.formlable{
    background-color: #32383e;
    color: #ced4da;
    display:flex;
    width: 100px;
    /* font-size: 0.875rem; */
    /* font-weight: 400; */
    /* line-height: 1.5; */
    /* background-position: right 0.9rem center; */
    /* background-size: 16px 12px; */
    /* border: 1px; */
    border-radius: 0.25rem;
    appearance: none;  
}
