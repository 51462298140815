.division{
    width: 100%;
}
.booking_division{
    display: flex;
    width: 100%;
    align-items: center;
}
.booking_division .globe_icon{
    margin-right: .2rem;
}
.booking_division .globe_icon i{
    font-size: 1.5rem;
}
.booking_division .bookingid_type{
    margin-top: 1rem;
}
.booking_division .bookingid_type h4{
    font-size: .5rem;
}
.booking_division .bookingid_type p{
    font-size: .6rem;
}
.division .booking_created{
    font-size: .6rem;
}

.customers_division{
    width: 100%;
    margin-top: .2rem;
}
.customers_division h3{
    font-size: .8rem;
}
.customers_division p{
    font-size: .7rem;
}
.details_division{
    width: 100%;
}
.details_division h3{
    font-size: .9rem;
}
.date_time{
    width: 100%;
    /* line-height: 1.5rem; */
}

.booking_btn_details{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 0.8rem 0.1rem;
}
.booking_btn_details .booking_created{
    text-align: center;
}
.booking_btn_details .booking_created p{
    font-size: .8rem;
    border: 1 solid rgba(65, 63, 63, 0.1);
    padding: .2rem;
    background: green;
    border-radius: 1rem;
    margin-bottom: .2rem;
}
.booking_btn_details .booking_created span{
    font-size: .8rem;
    color: #999;
    white-space: 0;
    margin-top: .2rem;
}
.booking_btn_details select{
    font-size: .9rem;
    border-radius: 1rem;
    padding: .1rem;
    text-align: center;
    background-color: orange;
    color: #fff;
    width: 22%;
}
.booking_btn_details select option{
    font-size: .8;
    font-weight: 600;
  
}
.booking_btn_details .driver_details{
    font-size: .8rem;
    border-radius: 1rem;
    width: 16%;
    padding: .2rem;
    /* border: 1 solid rgba(65, 63, 63, 0.1); */
    border: none;
    background: #666;
    color: #fff;
    font-weight: 600;
}

.booking_btn_details .otp_verified{
    /* display: block; */
    font-size: .8rem;
    border-radius: 1rem;
    width: 16%;
    padding: .2rem;
    /* border: 1 solid rgba(65, 63, 63, 0.1); */
    border: none;
    background: #666;
    color: #fff;
    font-weight: 600;
    text-align: center;
    margin-top: 1rem;
}
  #reporting_time{
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    border: 1px solid #32383e;
    border-radius: 0.25rem;
    width: 100%;
    padding: 0.5rem 0.9rem;
    line-height: 1.5;
    appearance: none;
    color: #ced4da;
    background-clip: padding-box;
    /* border-color: #32383e; */
    background-color: #212529;
  }
.datecontrol{
    background-color:  #212529;
    color: white
  }
  